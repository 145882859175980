import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/index'
import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import currentConfig from './currentConfig'
import Vuelidate from 'vuelidate'
import VModal from 'vue-js-modal'

Amplify.configure(currentConfig)

Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(Vuelidate)
Vue.use(VModal, { dialog: true })
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
