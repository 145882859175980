<template>
  <div class="login">
    <div class="mini_banner animated fadeInDown slow"></div>
    <div v-if="login_vue" class="login-vue">
      <p class="title-component section-title bold-text animated fadeInUp slower">
        Ouvrir une session
      </p>
      <div v-if="serverError" class="error-message">{{ serverError }}</div>
      <p class="small-text bold-text animated fadeInRight">Adresse courriel</p>
      <div class="editType animated fadeInLeft">
        <input class="animated fadeInLeft" style="width:90%" name="email" type="text" v-model="login" />
      </div>
      <p class="small-text bold-text animated fadeInRight">mot de passe</p>
      <div class="editType animated fadeInLeft">
        <input
          style="width:90%"
          name="password"
          type="password"
          class="animated fadeInLeft"
          v-model="password"
        />
      </div>
      <p
        @click="forgetpsw"
        class="small-text bold-text forget-psw animated fadeInLeft slow"
      >
        mot de passe oublié?
      </p>
      <button
        @click="signIn"
        class="login-button bold-text animated slideInUp"
        :disabled="loading"
      >
        <div v-if="loading" class="lds-ring-container">
          <div class="lds-dual-ring"></div>
        </div>
        connexion
      </button>
    </div>
    <component v-if="forgot_psw" v-bind:is="component_psw"> </component>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { AmplifyEventBus } from "aws-amplify-vue";
import ForgotPassword from "../authentification/ForgotPassword";
import { mapActions } from "vuex";

export default {
  name: "Login",
  components: {
    ForgotPassword,
  },

  data() {
    return {
      login: "",
      password: "",
      login_vue: true,
      forgot_psw: false,
      component_psw: ForgotPassword,
      serverError: "",
      loading: false,
    };
  },
  created() {
    this.isUserSignedIn();
    AmplifyEventBus.$on("authState", (info) => {
      if (info === "signedIn") {
        this.isUserSignedIn();
      } else {
        this.$store.state.signedIn = false;
        this.$store.state.user = null;
      }
    });
  },
  computed: {
    signedIn() {
      return this.$store.state.signedIn;
    },
  },
  methods: {
    ...mapActions(["resetCartState"]),
    forgetpsw() {
      this.$store.email_form = this.login;
      (this.forgot_psw = !this.forgot_psw), (this.login_vue = !this.login_vue);
    },
    signIn() {
      this.loading = true;
      Auth.signIn(this.login, this.password)
        .then((user) => {
          this.resetCartState;
          this.loading = false;
          this.$store.state.signedIn = !!user;
          this.$store.state.user = user;
          this.$router.push({ path: "dashboard" });
        })
        .catch((err) => {
          const code_err = err.code;
          console.log("signIn() failed, error : " + code_err);
          this.loading = false;
          switch (code_err) {
            case "UserNotConfirmedException":
              this.serverError = "Ce compte n'est pas encore vérifié.";
              return false;
            case "UserNotFoundException":
              this.serverError =
                "Ce courriel n'existe pas dans notre base de donnée.";
              return false;
            case "NotAuthorizedException":
              this.serverError = "Mot de passe invalide.";
              return false;
            default:
              return false;
          }
        });
    },
    signOut() {
      Auth.signOut()
        .then((data) => {
          this.$store.state.signedIn = !!data;
        })
        .catch((err) => console.log(err));
    },
    async isUserSignedIn() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        this.$store.state.signedIn = true;
        this.$store.state.user = user;
        this.$router.push({ path: "dashboard" });
      } catch (err) {
        this.$store.state.signedIn = false;
        this.$store.state.user = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mini_banner {
  //small bar behind text login
  position: absolute;
  width: 272px;
  height: 60px;
  left: 20px;
  background: #9fbaff;
  border-radius: 100px;
}
.forget-psw {
  text-decoration: underline;
}
.forget-psw:hover {
  cursor: pointer;
}
.headerDivider {
  // lign between section in large screen
  position: absolute;
  left: 50%;
  margin-top: 40px;
  border-right: 1px solid #3a50b3;
  height: 80%;
}
@media (max-width: 500px) {
  .card {
    border-radius: 0px;
  }
  .title-open-session {
    margin-top: 10px;
    font-size: 32px;
  }
}
</style>
