<template>
  <div class="brandWelcome">
    <div class="col s5 m12">
      <img
        src="@/assets/imagetaxes.png"
        class="imageTaxes animated slideInDown slow"
        alt=""
      />
    </div>
    <div class="col s7 m12">
      <div
        class="mini_banner-right hide-on-med-and-down animated fadeInDown slow"
      ></div>
      <p class="title-console section-title bold-text animated fadeInRight">
        Service en ligne des rapports de taxes (TPS TVQ) Revenu Québec
      </p>
    </div>

    <p class="under-text animated fadeInRight slow">
      propulsé par muh mobile
    </p>
  </div>
</template>

<script>
export default {
  name: "BrandWelcome",
};
</script>

<style lang="scss" scoped>
.title-console {
  margin-top: 0;
  font-weight: 600;
  line-height: 53px;
  text-align: left;
  margin-left:15px;
  margin-bottom: 4px;
}
.imageTaxes {
  width: 141px;
  height: 156px;
  margin-top: 10px;
}
.mini_banner-right {
  //small bar behind title-console
  position: absolute;
  width: 232px;
  height: 60px;
  margin-top:-3px;
  background: #9fbaff;
  border-radius: 100px;
}
.under-text {
  // text populse par muh
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #6072c2;
  font-weight: bold;
  margin-top: 0px;

}
@media (max-width: 500px) {
  .title-console {
    text-align: left;
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 0;
  }
  .under-text {
    font-size: 12px;
    line-height: 20px;
  }
  .imageTaxes {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-top: -10px;
    margin-left:10px;
  }
  .register {
    font-size: 18px;
    line-height: 43px;
  }
}
</style>
