import Vue from 'vue'
import Vuex from 'vuex'
import receipts from './modules/receipts'
import clients from './modules/clients'

Vue.use(Vuex)

export default new Vuex.Store({
  modules : {
    receipts, clients
  }
})