<template>
  <div class="register">
    <div class="mini_banner animated fadeInRight slow"></div>
    <div v-if="register_vue" class="register-vue">
      <div class="formSignUP" v-if="completeTheForm">
        <p class="title-create-account section-title bold-text animated fadeInRight slower">
          Ouvrir un compte
        </p>
        <div v-if="serverError" class="error-message">{{ serverError }}</div>
        <p class="small-text bold-text animated fadeInRight">
          Adresse courriel valide*
        </p>
        <div class="editType animated fadeInRight">
          <input style="width:90%" type="text" v-model="email" />
        </div>
        <p class="small-text bold-text animated fadeInRight">
          mot de passe, 8 caractères ou plus*
        </p>
        <div class="editType animated fadeInRight">
          <input style="width:90%" type="password" v-model="password1" />
        </div>
        <p class="small-text bold-text animated fadeInRight">
          mot de passe, à nouveau*
        </p>
        <div class="editType animated fadeInRight">
          <input style="width:90%" type="password" v-model="password2" />
        </div>
        <p class="small-text bold-text animated fadeInRight">
          numéro de téléphone*
        </p>
        <div class="editType animated fadeInRight">
          <input
            style="width:90%"
            type="text"
            v-model="phone_number"
            @input="acceptNumber"
          />
        </div>
        <p
          @click="alreadyCode"
          v-if="alreadyCodeToggle"
          class="small-text bold-text alreay-code animated fadeInRight slower"
        >
          ou déjà un code?
        </p>
        <button
          @click="submitAccount"
          class="login-button bold-text animated slideInRight"
        >
          <div v-if="loading" class="lds-ring-container">
            <div class="lds-dual-ring"></div>
          </div>
          s'enregistrer
        </button>
      </div>
      <div v-if="code_verif">
        <CodeVerif />
      </div>
    </div>
    <component v-if="have_code" v-bind:is="component_codeVerif"> </component>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import CodeVerif from "../authentification/CodeVerif";

export default {
  name: "Register",
  components: {
    CodeVerif,
  },
  data() {
    return {
      username: "",
      email: "",
      password1: "",
      password2: "",
      phone_number: "",
      code: "",
      completeTheForm: true,
      code_verif: false,
      serverError: "",
      loading: false,
      alreadyCodeToggle: true,
      component_codeVerif: CodeVerif,
      have_code: false,
      register_vue: true,
    };
  },
  methods: {
    alreadyCode() {
      (this.have_code = !this.have_code),
        (this.register_vue = !this.register_vue);
      this.alreadyCodeToggle = false;
    },
    acceptNumber() {
      var x = this.phone_number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone_number = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    submitAccount() {
      if (this.password1 == this.password2) {
        this.loading = true;
        var phone = "";
        var x = this.phone_number
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.phone = !x[2] ? x[1] : x[1] + x[2] + x[3];

        phone = this.phone;

        Auth.signUp({
          email: this.email,
          username: this.email,
          password: this.password1,
          attributes: {
            phone_number: "+1" + phone,
          },

          validationData: [], // optional
        })
          .then((data) => {
            if (data) {
              this.loading = false;
              console.log(data),
                ((this.completeTheForm = false), (this.code_verif = true));
              this.serverError = "";
              this.$store.state.email_form = this.email;
            }
          })
          .catch((err) => {
            const code_err = err.code;
            this.loading = false;
            console.log(err);
            switch (code_err) {
              case "UsernameExistsException":
                this.serverError = "Adresse courriel existe déjà";
                return false;
              case "InvalidPasswordException":
                this.serverError =
                  "Mot de passe invalide. Doit contenir un minimum de 8 caractères";
                return false;
              default:
                return false;
            }
          });
      } else {
        this.serverError = "Les mots de passe ne sont identiques.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-create-account {
  margin-left: 30px;
  margin-top: 70px;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 58px;
  text-align: center;
  text-align: left;
  position: relative;
}
.mini_banner {
  //small bar behind text login
  position: absolute;
  width: 272px;
  height: 60px;
  left: 20px;
  background: #9fbaff;
  border-radius: 100px;
}
.headerDivider {
  // lign between section in large screen
  position: absolute;
  left: 50%;
  margin-top: 40px;
  border-right: 1px solid #3a50b3;
  height: 80%;
}
.alreay-code {
  text-decoration: underline;
}
.alreay-code:hover {
  cursor: pointer;
}
@media (max-width: 500px) {
  .card {
    border-radius: 0px;
  }
  .title-open-session {
    margin-top: 10px;
    font-size: 32px;
  }
}
</style>
