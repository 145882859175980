<template>
<div>
  <div class="forgotPassword" v-if="forgot_vue">
    <p class="title-component section-title bold-text animated fadeInUp slower">
      Mot de passe oublié
    </p>
    <div v-if="send_code" class="send_code">
      <p class="small-text bold-text">Adresse courriel</p>
      <div class="editType">
        <input style="width:90%" name="email" type="text" v-model="email" />
      </div>
      <p
        @click="backLogin"
        class="small-text bold-text goBack animated fadeInLeft slow"
      >
        retour
      </p>
      <button
        @click="sendCode"
        class="login-button sendButton bold-text animated slideInRight"
      >
        <div v-if="loading" class="lds-ring-container">
          <div class="lds-dual-ring"></div>
        </div>
        envoyer un code
      </button>
    </div>
    <div class="forgotPassword_code" v-if="verif_code">
      <h4 class="bold-text animated fadeInRight slow">
        Un courriel vous a été envoyé, entrer le code ici.
      </h4>
      <div v-if="serverError" class="error-message">{{ serverError }}</div>
      <p class="small-text bold-text animated fadeInRight">
        code
      </p>
      <div class="editType animated fadeInRight">
        <input style="width:90%" type="text" v-model="code" />
      </div>
      <p class="small-text bold-text animated fadeInRight">
        mot de passe, 8 caractères ou plus*
      </p>
      <div class="editType animated fadeInRight">
        <input style="width:90%" type="password" v-model="new_password1" />
      </div>
      <p class="small-text bold-text animated fadeInRight">
        mot de passe, à nouveau*
      </p>
      <div class="editType animated fadeInRight">
        <input style="width:90%" type="password" v-model="new_password2" />
      </div>
      <button
        @click="confirmCode"
        class="login-button bold-text animated slideInRight"
      >
        <div v-if="loading" class="lds-ring-container">
          <div class="lds-dual-ring"></div>
        </div>
        confirmer
      </button>
    </div>
    
  </div>
  <component v-if="login_vue" v-bind:is="component_login"></component>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import Login from "../authentification/Login";

export default {
  name: "ForgotPassword",

  components: {
    Login
  },

  data() {
    return {
      send_code: true,
      verif_code: false,
      email: this.$store.email_form,
      code: "",
      new_password1: "",
      new_password2: "",
      serverError: "",
      loading: false,
      component_login: Login,
      login_vue: false,
      forgot_vue: true,
    };
  },

  methods: {
    backLogin() {
      (this.forgot_vue = !this.forgot_vue), (this.login_vue = !this.login_vue);
    },

    confirmCode() {
      if (this.new_password1 == this.new_password2) {
        this.loading = true;
        // Collect confirmation code and new password , then
        Auth.forgotPasswordSubmit(this.email, this.code, this.new_password1)
          .then((data) => {
            this.loading = false;
            console.log(data);
            this.$router.go();
          })
          .catch((err) => {
            this.loading = false;
            const code_err = err.code;
            console.log(err);

            if (err.message == "Password cannot be empty") {
              this.serverError =
                "Entrer un mot de passe de 8 caractères minimum";
            } else if (err.message == "Confirmation code cannot be empty") {
              this.serverError = "Ne pas laisser le champs du code vide.";
            }
            switch (code_err) {
              case "CodeMismatchException":
                this.serverError = "Code Invalide";
                return false;
              default:
                return false;
            }
          });
      } else {
        this.serverError = "Les mots de passe ne sont identiques.";
      }
    },
    sendCode() {
      Auth.forgotPassword(this.email)
        .then((data) => {
          console.log(data);
          if (data) {
            this.send_code = !this.send_code;
            this.verif_code = !this.verif_code;
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
.goBack {
  text-decoration: underline;
  margin-top:7px
}
.goBack:hover {
  cursor: pointer;
}
.send_code {
  padding-top: 10px;
}
.sendButton{
  margin-top: 3px;
}
</style>
