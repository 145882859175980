import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'ca-central-1:d01867f5-312f-4ca1-92c4-69c763d0c1df',

        // REQUIRED - Amazon Cognito Region
        region: 'ca-central-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ca-central-1_K3BrzsLk9',

        userPoolWebClientId: '6ajfukerorap2f8g1cv1fdnsp5'
    }
});

export default Auth.configure();