<template>
  <div class="codeVerif">
    <p class="title-code_verif section-title bold-text animated fadeInRight slower">
      Vérification du code
    </p>
    <h4 class="bold-text animated fadeInRight slow">
      Un courriel vous a été envoyé, entrer le code ici.
    </h4>
    <div v-if="serverError" class="error-message">{{ serverError }}</div>
    <p class="small-text bold-text animated fadeInRight">Adresse courriel</p>
    <div class="editType animated fadeInRight">
      <input style="width:90%" name="email" type="text" v-model="username" />
    </div>
    <p class="small-text bold-text animated fadeInRight">
      code
    </p>
    <div class="editType animated fadeInRight">
      <input style="width:90%" type="text" v-model="code" />
    </div>
    <button
      @click="confirmCode"
      class="login-button bold-text animated slideInRight"
    >
      <div v-if="loading" class="lds-ring-container">
        <div class="lds-dual-ring"></div>
      </div>
      confirmer
    </button>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "CodeVerif",

  data() {
    return {
      code: "",
      serverError: "",
      loading: false,
      username: this.$store.state.email_form,
    };
  },

  methods: {
    confirmCode() {
      this.loading = true;
      // After retrieveing the confirmation code from the user
      Auth.confirmSignUp(this.username, this.code, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      })
        .catch((err) => {
          const code_err = err.code;
          console.log(err);
          this.loading = false;

          if (err.message == "Username cannot be empty") {
            this.serverError =
              "Adresse courriel associé au compte est obligatoire";
          }
          switch (code_err) {
            case "UserNotFoundException":
              this.serverError = "Adresse courriel inconnue";
              return false;
            case "NotAuthorizedException":
              if (
                err.message ==
                "User cannot be confirmed. Current status is CONFIRMED"
              ) {
                this.$router.go();
              }
              return false;
            case "CodeMismatchException":
              this.serverError = "Code Invalide";
              return false;
            case "UserNotConfirmedException":
              return false;
            default:
              return false;
          }
        })
        .then((user) => {
          if (user) {
            this.loading = false;
            this.$router.go();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-code_verif {
  margin-left: 30px;
  margin-top: 70px;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 58px;
  text-align: center;
  text-align: left;
  position: relative;
}
</style>
