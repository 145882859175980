<template>
  <div id="app">
    <Header />
    <main class="Site-content container">
      <div
        class="large_banner rect1 animated fadeInRightBig hide-on-med-and-down"
      ></div>
      <div
        class="large_banner rect2 animated fadeInLeftBig slow hide-on-med-and-down"
      ></div>
      <div
        class="large_banner rect3 animated fadeInRightBig slower hide-on-med-and-down"
      ></div>
      <div class="col s12">
        <div class="card horizontal">
          <div class="card-stacked">
            <div class="row flex-s">
              <div class="col l6 m12 box-a">
                <div
                  class="headerDivider hide-on-med-and-down animated slideInDown slow"
                ></div>
                <!-- section login // register -->
                <component v-bind:is="currentComponentSideA"> </component>
                <button
                  class="register-button bold-text animated fadeInUp slower hide-on-med-and-up"
                  @click="changeComponent"
                  v-if="registerButton"
                >
                  s'enregistrer
                </button>
              </div>
              <!-- section intro-console -->
              <div class="col l6 m12 box-b">
                <component v-bind:is="currentComponentSideB"> </component>
                <button
                  @click="changeComponent"
                  v-if="registerButton"
                  class="register-button bold-text animated fadeInUp slower hide-on-small-only"
                >
                  s'enregistrer
                </button>
                <button
                  class="login-button bold-text animated fadeInRight slow"
                  @click="changeComponentToLogin"
                  v-if="loginButton"
                >
                  retour
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Login from "../components/authentification/Login";
import Register from "../components/authentification/Register";
import CodeVerif from "../components/authentification/CodeVerif";
import BrandWelcome from "../components/authentification/BrandWelcome";

export default {
  name: "Home",
  components: {
    Header,
    Login,
    Register,
    CodeVerif,
    BrandWelcome,
    Footer,
  },
  data() {
    return {
      currentComponentSideA: Login,
      currentComponentSideB: BrandWelcome,
      registerButton: true,
      loginButton: false,
    };
  },
  methods: {
    changeComponent() {
      this.currentComponentSideA = Register;
      this.registerButton = false;
      this.loginButton = true;
    },
    changeComponentToLogin() {
      this.currentComponentSideA = Login;
      this.loginButton = false;
      this.registerButton = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 7vh;
  padding-top: 2rem;
  background: #fafafa;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  padding-bottom: 2rem;
}
.large_banner {
  //bar behind the form
  position: absolute;
  width: 50%;
  height: 140px;
  border-radius: 100px;
}
.rect1 {
  // bar behind the form
  left: 104px;
  top: 94px;
  background: rgb(58, 80, 179);
  background: linear-gradient(
    90deg,
    rgba(58, 80, 179, 1) 0%,
    rgba(133, 142, 250, 1) 62%,
    rgba(159, 186, 255, 1) 100%
  );
}
.rect2 {
  // bar behind the form
  left: 104px;
  top: 322px;
  width: 85%;
  background: rgb(159, 186, 255);
  background: linear-gradient(
    90deg,
    rgba(159, 186, 255, 1) 0%,
    rgba(133, 142, 250, 1) 21%,
    rgba(58, 80, 179, 1) 100%
  );
}
.rect3 {
  // bar behind the form
  left: 540px;
  top: 550px;
  background: rgb(58, 80, 179);
  background: linear-gradient(
    90deg,
    rgba(58, 80, 179, 1) 0%,
    rgba(133, 142, 250, 1) 62%,
    rgba(159, 186, 255, 1) 100%
  );
}
.headerDivider {
  // lign between section in large screen
  position: absolute;
  left: 50%;
  margin-top: 40px;
  border-right: 1px solid #3a50b3;
  height: 80%;
}
.bold-text {
  // all the principle text
  color: #3a50b3;
  font-family: "Blinker", sans-serif;
}
.register-button,
.login-button {
  // button premiere utilisation
  width: 215px;
  height: 56px;
  border: 1px solid #3a50b3;
  border-radius: 50px;
  font-size: 22px;
  background-color: Transparent;
  margin-top: 20px;
}
.register-button:hover,
.login-button:hover {
  background-color: #3a50b3;
  color: white;
  cursor: pointer;
}
.material-icons {
  // arrow
  display: inline-flex;
  vertical-align: top;
}
@media (max-width: 500px) {
  .card {
    border-radius: 0px;
  }
  .flex-s {
    display: flex;
    flex-direction: column; /* Stack on top */
  }
  .box-a {
    order: 2; /* Go down, bring Box B up */
  }
  .small-screen {
    display: flex;
    flex-direction: column;
  }
  .register {
    font-size: 22px;
    line-height: 43px;
  }
}
</style>
